import * as React from "react"
import { Layout } from 'antd'
import { useStaticQuery, graphql } from 'gatsby'

import MainLayout from "../../Layouts/layoutLanding"
import Seo from "../../components/seo"
import BrandCarousel from "../../components/Brand/Carousel"
import BrandDetails from "../../components/Brand/BrandDetails"
import BrandContactDetails from "../../components/Brand/ContactDetails"
import BrandHotelCarousel from "../../components/Brand/Hotels"
import BrandSections from "../../components/Brand/Sections"

const Brand = ({ data, pageContext }) => {
  const { brandid, brands } = pageContext;
  const brand = brands.filter(item=>item.id==brandid)[0];
 
  return (
    <MainLayout pageContext={pageContext} title="brands inside-pages">
    <Seo title={brand.name} description={brand.short_description} lang="en"/>
      <Layout className="brand-carousel">
        <BrandCarousel data={data} brand={brand}/>
        <BrandDetails data={data} brand={brand} />
      </Layout>
      <Layout className="brand-sections" style={brand && brand.sections?.length === 0 ? {display: "none"} : {}}>
        <BrandSections data={data} brand={brand} />
      </Layout>
      <Layout className="brand-hotels" style={brand && brand.hotels?.length === 0 ? {display: "none"} : {}}>
        <BrandHotelCarousel data={data} brand={brand} />
      </Layout>
      <Layout className="brand-contact-details">
        <BrandContactDetails data={data} brand={brand} />
      </Layout>
    </MainLayout>
  )
}

export default Brand;

export const query = graphql`
query GetBrandBYID($brandid: String!) {
  tarsiergraphql {
    getBrandById(id: $brandid) {
      id
      name
      logo
      long_description
      redirect_link
      uploaded_media {
        uploaded_media_type {
          name
          description
        }
        url
      }
      landline_nos {
        input
      }
      mobile_nos {
        country_code
        input
      }
      email_addresses {
        input
      }
      social_media {
        social_media_type {
          name
        }
        url
      }
      hotels {
        name
        website_logo
        address
        website_link
      }
      sections {
        id
        order
        section_status
        section_type {
          name
        }
        header_title
        text_content
        photo_thumbnail_url
        link
        pdf_url
      }
    }
  }
}`