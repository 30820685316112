import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser';

import brandLogo from '../../assets/images/brands/go_logo.png'

import { BrandsI } from '../../interface'

import './Brand.scss'

const BrandDetails = ({data, brand: brandFromNode}) => {

    const [brand, setBrands] = useState<BrandsI>()

    useEffect(() =>{
        setBrands(data.tarsiergraphql.getBrandById)
    }, [brand])

    const onErrorImg = (e) => {
        e.target.src = brandLogo;
    }

    return (
        <div className="brand-derscription rhr-container">
            <div className="brand-des-wrap">
                <img src={brandFromNode?.logo} onError={onErrorImg} alt="Brand Logo"/>
                <h1>{brandFromNode?.name}</h1>
                <article>{ReactHtmlParser(brandFromNode?.long_description)}</article>
                <a href={brandFromNode?.redirect_link} target="_blank" className="rhr-button">Know More</a>
            </div>
        </div>
    )
}

export default BrandDetails
