import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'

import { BrandsI } from '../../interface'
import { SOCIAL } from '../../enum'

import Facebook from "../../assets/images/social-medias/ic_fb.svg"
import FacebookRed from "../../assets/images/social-medias/ic_fb_red.svg"
import Twitter from "../../assets/images/social-medias/ic_twitter.svg"
import TwitterRed from "../../assets/images/social-medias/ic_twitter_red.svg"
import Instagram from "../../assets/images/social-medias/ic_ig.svg"
import InstagramRed from "../../assets/images/social-medias/ic_ig_red.svg"
import Linkedin from "../../assets/images/social-medias/ic_linkedin.svg"
import LinkedinRed from "../../assets/images/social-medias/ic_linkedin_red.svg"
import Kumu from "../../assets/images/social-medias/ic_kumu.png"
import Youtube from "../../assets/images/social-medias/ic_youtube.svg"
import Viber from "../../assets/images/social-medias/ic_viber.svg"
import Tiktok from "../../assets/images/social-medias/ic_tiktok.svg"

const ContactDetails = ({ data, brand }) => {


    const socialIcons = (param) => {
        switch(param) {
            case SOCIAL.FACEBOOK:
                return Facebook;

            case SOCIAL.INSTAGRAM:
                return Instagram;

            case SOCIAL.TWITTER:
                return Twitter;

            case SOCIAL.LINKEDIN:
                return Linkedin;

            case SOCIAL.KUMU:
                return Kumu;

            case SOCIAL.VIBER:
                return Viber;

            case SOCIAL.TIKTOK:
                return Tiktok;

            case SOCIAL.YOUTUBE:
                return Youtube;

            default:
                return Facebook;
        }
    }

    const formatlandlineNumber = (landline: string) => {
        let cleaned = ('' + landline).replace(/\D/g, '');
        let match = cleaned.match(/^(63|)?(\d{1})(\d{1})(\d{3})(\d{4})$/);
        if (match) {
          let intlCode = (match[1] ? '+63 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], ' ', match[4], ' ', match[5]].join('');
        }
        return null;
    }

    const formatMobileNumber = (mobile: string) => {
        let cleaned = ('' + mobile).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return [match[1], ' ', match[2], ' ', match[3]].join('');
        }
        return null;
    }
    
    return (
        <div className="contact-details paddingpX50">
            <Row>
                <Col xs={20} xl={12} className="contact-copy">
                    <article>
                        <span className="rhr-subtitle">Contact</span>
                        <h2 className="title-border">Information</h2>
                    </article>
                </Col>
                <Col xs={25} xl={12} className="contact-info">
                    <Row>
                        {brand?.landline_nos?.filter((landline) => landline.input !== "").length !== 0 && (
                            <Col xs={10} xl={12} className="contact-wrap">
                                <span className="rhr-subtitle">Landline No.</span>
                                <ul>
                                    {brand?.landline_nos?.map((landline) => (
                                        <li>{formatlandlineNumber(landline.input)}</li>
                                    ))}
                                </ul>
                            </Col>
                        )}
                        {brand?.email_addresses?.filter((email) => email.input !== "").length !== 0 && (
                            <Col xs={12} xl={12} className="contact-wrap">
                                <span className="rhr-subtitle">Email Address</span>
                                <ul>
                                    {brand?.email_addresses?.map((email) => (
                                        <li>{email.input}</li>
                                    ))}
                                </ul>
                            </Col>
                        )}
                        {brand?.mobile_nos?.filter((mobile) => mobile.input !== "").length !== 0 && (
                            <Col xs={12} xl={12} className="contact-wrap">
                                <span className="rhr-subtitle">Mobile No.</span>
                                <ul>
                                    {brand?.mobile_nos?.map((mobile) => (
                                        <li>{mobile.country_code +' '+ formatMobileNumber(mobile.input)}</li>
                                    ))}
                                </ul>
                            </Col>
                        )}
                        {brand?.social_media?.length !== 0 && (
                            <Col xs={25} xl={12} className="contact-wrap">
                                <span className="rhr-subtitle">Social Media</span>
                                <div className="social-container">
                                    {brand?.social_media?.sort((a, b) => a.social_media_type.name.localeCompare(b.social_media_type.name))
                                        .map((social, i) => (
                                        <a href={social.url} target="_blank" className="social-link">
                                            <img 
                                                src={socialIcons(social.social_media_type.name)} 
                                                alt={social.social_media_type.name}
                                            />
                                        </a>
                                    ))}
                                </div>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default ContactDetails
