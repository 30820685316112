import React, { useEffect, useState } from 'react'
import { Carousel, Row, Col } from 'antd'

import chevronRed from '../../assets/images/ic_chevron_right_24px_red.svg'
import locationLogo from '../../assets/images/ic_place_24px-blue.svg'
import callbackImage from '../../assets/images/callback-image-no-BG.png'

import { BrandsI } from '../../interface'

const Hotels = ({data, brand}) => {


    const [slideShow, setSlideShow] = useState<number>();


    useEffect(() => {
        setSlideShow(window.innerWidth > 812 ? 4:1);
        const handleResize = () => {
          setSlideShow(window.innerWidth > 812 ? 4:1);
        };
        window.addEventListener("resize", handleResize);
        
        return () => window.removeEventListener("resize", handleResize);
    }, [slideShow]);


    const onErrorImg = (e) => {
        e.target.src = callbackImage;
    }
    
    return (
        <Row className="hotels-carousel paddingpX50">
            <Col xs={25} className="hotel-carousel-list custom-arrows-carousel">
                <Carousel infinite={false} arrows dots={false} slidesToShow={slideShow} slidesToScroll={1}>
                    {brand?.hotels?.map((hotel, index) => (
                        <Col xs={25} className="hotel-carousel-card" key={index}>
                            <div className="hotel-carousel-wrap">
                                    <div className="hotel-carousel-img">
                                    <img src={hotel.website_logo} onError={onErrorImg} alt="Hotel Logo" className="img-responsive"/>
                                </div>
                                <div className="hotel-carousel-details">
                                    <h3 className="rhr-subtitle">{hotel.name}</h3>
                                    <p><img src={locationLogo} alt="arrow right" />{hotel.address}</p>
                                    <a href={hotel.website_link} target="_blank" className="know-more">Know More <img src={chevronRed} alt="arrow right" /></a>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Carousel>
            </Col>
        </Row>
    )
}

export default Hotels
