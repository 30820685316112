import React, { useEffect, useState } from 'react'

import { SECTION, SECTIONSTATUS } from '../../enum'
import { BrandsI } from '../../interface'
import { CenterALign, CenterBottom, CenterTop, FeaturedVideo, LeftAlign, LeftSide, RightAlign, RightSide } from './SectionsTemplate'


const Sections = ({data, brand}) => {

    

    const sectiontemplate = (section: string, id: string) => {
        const details = brand?.sections.filter((brandSection) => brandSection.section_type.name === section && brandSection.id === id);

        switch(section) {
            case SECTION.FEATUREDVIDEO:
                return <FeaturedVideo details={details}/>;

            case SECTION.LEFTSIDE:
                return <LeftSide details={details}/>;

            case SECTION.RIGHTSIDE:
                return <RightSide details={details}/>;

            case SECTION.CENTERTOP:
                return <CenterTop details={details}/>;
            
            case SECTION.CENTERBOTTOM:
                return <CenterBottom details={details}/>;

            case SECTION.LEFTALIGN:
                return <LeftAlign details={details}/>;

            case SECTION.RIGHTALIGN:
                return <RightAlign details={details}/>;

            case SECTION.CENTERALIGN:
                return <CenterALign details={details}/>;

            default:
                return <div>section has no template yet.</div>;
        }
    }
    
    return (
        <div className="brand-section-container">
            {brand?.sections?.filter((section) => section.section_status === SECTIONSTATUS.ENABLED).map((brandSection, i) => (
                <div className="brand-stack">
                    {sectiontemplate(brandSection.section_type.name, brandSection.id)}
                </div>
            ))}
        </div>
    )
}

export default Sections
