import React, {useEffect, useState} from 'react'
import { Carousel } from 'antd'

import bannerOverlay from '../../assets/images/Overlay-Carousel-BG.png'

import brandLogo from '../../assets/images/brands/go_logo.png'

import { BrandsI } from '../../interface'

const Index = ({data, brand:brandFromNode}) => {
    const [brand, setBrands] = useState<BrandsI>()

    useEffect(() =>{
        setBrands(data.tarsiergraphql.getBrandById)
    }, [brand])

    const onErrorImg = (e) => {
        e.target.src = brandLogo;
    }
    return (
        <div className="brand-carousel-container carousel-dots-custom">
           <Carousel autoplay autoplaySpeed={5000}>
                {brandFromNode?.uploaded_media
                    ?.filter((b) => b.uploaded_media_type.name === "banner_feature_image")
                    .map((filteredBrand) => (
                    <div className="slider">
                        <img src={bannerOverlay} alt="Banner Image" className="overlay-banner"/>
                        <img src={filteredBrand.url} onError={onErrorImg}  alt="Banner Image" />
                    </div>
                ))}
            </Carousel>
        </div>
    )
}

export default Index
